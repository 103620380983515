import { useTranslation } from 'react-i18next';
import Info from '../../components/common/Info';
import SubInfo from '../../components/common/SubInfo';
import GapAround from '../../components/hoc/GapAround';
import Carousel from '../../components/common/Carousel';
import { imageUrl } from '../../utils';
import { useMediaQuery } from '@mui/material';

const OurExpertise = ({ title, description, displayItems }: any) => {
  const { t } = useTranslation();
  const isLessThan1280 = useMediaQuery('(max-width: 1280px)');
  const isLessThan640 = useMediaQuery('(max-width: 640px)');
  return (
    <div className="outline relative outline-[1px] outline-gray-200" id="expertise">
      <div className="">
        <div
          className="bg-top absolute w-full h-full -z-10 opacity-10"
          style={{ backgroundImage: `url(${imageUrl('lightbulb.svg')})`}}></div>
        <GapAround childrenClasses="flex flex-col breaktwomin:flex-row h-full justify-center items-center">
          <div
            className={`left_part mdmax:w-[100%] w-[30%] xl:w-full h-full py-10 space-y-2 text-center breaktwomin:text-left flex flex-col justify-center breaktwo:py-6`}>
            <Info typographyStyles={{ color: '#263238', fontSize: isLessThan1280 ? (isLessThan640 ? 32 : 36) : 42 }}>
              {t(title)}
            </Info>
            <SubInfo typographyStyles={{ color: '#728F8B' }}>{t(description)}</SubInfo>
          </div>
          <div className="right_part mobile:!w-[calc(100%+40px)] mobile:mx-[-20px] w-[40%] xl:w-[60%] breaktwo:w-[90%] flex items-center">
            <div className="cursor-pointer hidden md:block swiper-go-left-enterprise w-80 flex justify-center pl-2 ml-[18%] sm:ml-10 breaktwomin:pl-0 breaktwomin:ml-0">
              <img src={imageUrl('arrow_left.svg')} alt=""></img>
            </div>
            <Carousel
              dataArray={displayItems}
              rowsPerSlide={isLessThan640 ? 1 : 2}
              slidesPerView={isLessThan640 ? 1 : isLessThan1280 ? 2 : 3}
              leftBtnClassName={'swiper-go-left-enterprise'}
              rightBtnClassName={'swiper-go-right-enterprise'}
              calledFrom="expertise"
              border
              autoplay
            />
            <div className="cursor-pointer hidden md:block swiper-go-right-enterprise w-80 flex justify-center pl-[5px] pr-2 mr-[18%] sm:mr-10 breaktwomin:pr-0 breaktwomin:mr-0">
              <img src={imageUrl('arrow_right.svg')} alt=""></img>
            </div>
          </div>
        </GapAround>
      </div>
    </div>
  );
};

export default OurExpertise;
