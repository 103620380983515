import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Info from '../../components/common/Info';
import ResultBox from '../../components/common/ResultBox';
import GapAround from '../../components/hoc/GapAround';
import { imageUrl } from '../../utils';
import { useMediaQuery } from '@mui/material';

const StepsWeFollow = () => {
  const { t } = useTranslation();
  const isLessThan640 = useMediaQuery('(max-width: 640px)');
  const isLessThan1024 = useMediaQuery('(max-width: 1024px)');
  const isLessThan1150 = useMediaQuery('(max-width: 1150px)');
  const isLessThan1280 = useMediaQuery('(max-width: 1280px)');

  const resultBoxClasses = isLessThan640
    ? 'grid grid-cols-1 gap-4 mdmax:mx-0 mx-4'
    : 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mx-4 md:mx-2';

  return (
    <div className={classNames("smmax:mx-5 mdmax:mx-0 breaktwo:my-6" , isLessThan1024 ? 'mt-16' : 'mt-24', isLessThan1150 ? 'mx-10' : '')}>
      <GapAround childrenClasses="space-y-5 mb-12 breaktwo:mb-4 lgspace-y-10 lg:mb-20">
        <div className="image_and_info flex items-center mdmax:space-x-0 space-x-6 mb-16 breaktwo:relative">
          <div className="flex-1 breaktwo:absolute breaktwo:top-24 breaktwo:opacity-30">
            <img loading="lazy" className="max-w-full" width={'538px'} height={'385px'} src={imageUrl('step_to_cup.svg')} alt="" />
          </div>
          <div className="flex-1 breaktwo:text-center">
            <Info
              typographyStyles={{
                fontSize: isLessThan1280 ? (isLessThan640 ? 32 : 36) : 42,
              }}>
              {t('steps_we_follow')}
            </Info>
          </div>
        </div>
        <div className="absolute left-0 -top-[6rem] z-[0.9] breaktwo:hidden">
          <img loading='lazy' src={imageUrl('single_flower_right.svg')} alt="" />
        </div>

        <div className={resultBoxClasses}>
          {/* <div className="breaktwo:hidden h-[2px] absolute top-[50%] left-0 right-0 -z-10 border-b-2 border-dashed border-[#263238]"></div> */}
          <ResultBox
            containerClasses="bg-light-green p-3 md:p-5"
            heading="01"
            subheading={t('discovery')}
            describeImage="discovery_icon.svg"
            headingStyle={{
              color: 'white',
              fontSize: isLessThan1280 ? (isLessThan1150 ? (isLessThan640 ? 36 : 48) : 54) : 60,
            }}
            subHeadingStyle={isLessThan640 ? { fontSize: 15, color: 'white' } : { fontSize: 30, color: 'white' }}
            iconStyle={{ width: isLessThan1150 ? 40 : 50, height: isLessThan1150 ? 40 : 50 }}
            noWidth
          />
          <ResultBox
            containerClasses="bg-[#F3F1F2]p-3 md:p-5"
            heading="02"
            subheading={t('design')}
            headingStyle={{ fontSize: isLessThan1280 ? (isLessThan1150 ? (isLessThan640 ? 36 : 48) : 54) : 60 }}
            describeImage="design_icon.svg"
            subHeadingStyle={isLessThan640 ? { fontSize: 15 } : { fontSize: 30 }}
            iconStyle={{ width: isLessThan1150 ? 40 : 50, height: isLessThan1150 ? 40 : 50 }}
            noWidth
          />
          <ResultBox
            containerClasses="bg-[#F8F8F8] p-3 md:p-5"
            heading="03"
            headingStyle={{ fontSize: isLessThan1280 ? (isLessThan1150 ? (isLessThan640 ? 36 : 48) : 54) : 60 }}
            subheading={t('develop')}
            describeImage="develop_icon.svg"
            subHeadingStyle={isLessThan640 ? { fontSize: 15 } : { fontSize: 30 }}
            iconStyle={{ width: isLessThan1150 ? 40 : 50, height: isLessThan1150 ? 40 : 50 }}
            noWidth
          />
          <ResultBox
            containerClasses="bg-[#F8F8F8] p-3 md:p-5"
            heading="04"
            headingStyle={{ fontSize: isLessThan1280 ? (isLessThan1150 ? (isLessThan640 ? 36 : 48) : 54) : 60 }}
            subheading={t('deliver')}
            describeImage="deliver_icon.svg"
            subHeadingStyle={isLessThan640 ? { fontSize: 15 } : { fontSize: 30 }}
            iconStyle={{ width: isLessThan1150 ? 40 : 50, height: isLessThan1150 ? 40 : 50 }}
            noWidth
          />
        </div>
      </GapAround>
    </div>
  );
};

export default StepsWeFollow;
