import { useTranslation } from 'react-i18next';
import Info from '../../components/common/Info';
import ResultBox from '../../components/common/ResultBox';
import RoundInfo from '../../components/common/RoundInfo';
import SubInfo from '../../components/common/SubInfo';
import GapAround from '../../components/hoc/GapAround';
import { imageUrl } from '../../utils';

const WhyUs = () => {
  const rocketImage = imageUrl('rocket.svg');
  const { t } = useTranslation();

  return (
    <GapAround spaceTaken={2} containerClass="overflow-hidden">
      <div className="w-full flex flex-col space-y-[73px] breaktwo:space-y-12" id="whyus">
        <div
          className="bg-top absolute w-[calc(100%+40px)] mx-[-20px] h-full -z-10 opacity-0 breaktwo:opacity-20"
          style={{ backgroundImage: `url(${imageUrl('rocket.svg')})`, backgroundSize: `250px` }}></div>
        <div className="flex flex-col breaktwo:items-center space-y-4 mt-0 md:mt-5 breaktwo:text-center">
          <RoundInfo containerClasses="mt-8">{t('scale_your_business')}</RoundInfo>
          <Info
            className="lg:!text-[42px] md:!text-[28px] !text-[28px]"
            containerClasses="w-[90%] mdmax:w-[100%] breaktwomin:w-[50%]">
            {t('dedicated_team')}
          </Info>
          <div className="w-[90%] mdmax:w-[100%] pb-4 breaktwomin:w-[50%]">
            <SubInfo>{t('top_talent_around_word')}</SubInfo>
          </div>
        </div>
        <div className="absolute right-[18%] bottom-52 -z-10 breaktwo:hidden">
          <img loading="lazy" src={rocketImage} alt="" />
        </div>
      </div>

      <div className="flex mdmax:flex-wrap mdmax:space-y-5 justify-between lg:space-x-10 md:space-x-5 mdmax:pb-[20px]">
        <ResultBox
          containerClasses="bg-light-gray/[0.2] p-3 md:p-5 xl:p-10 mdmax:w-[100%]"
          heading="1M+"
          subheading={t('revenue_tracked')}
          describeImage="earnings_icon2.svg"
          className="isMin1280:!text-[60px] isMin1150:!text-[54px] !text-[48px]"
          subHeadingClass="!text-[16px]"
          iconClass="w-[45px] h-[48px] pt-[2px]"
        />
        <ResultBox
          containerClasses="bg-light-gray/[0.3] p-3 md:p-5 xl:p-10 mdmax:w-[100%]"
          heading="4.9+/5"
          subheading={t('average_rating')}
          describeImage="stars.svg"
          className="isMin1280:!text-[60px] isMin1150:!text-[54px] !text-[48px]"
          subHeadingClass="!text-[16px]"
          iconClass="w-[45px] h-[48px] pt-[1px]"
        />
        <ResultBox
          containerClasses="bg-light-gray/[0.4] p-3 md:p-5 xl:p-10 mdmax:w-[100%]"
          heading="70+"
          subheading={t('happy_clients')}
          describeImage="happyfaces.svg"
          className="isMin1280:!text-[60px] isMin1150:!text-[54px] !text-[48px]"
          subHeadingClass="!text-[16px]"
          iconClass="w-[60px] h-[70px] pt-[7px]"
          imgClass="w-[100%]"
        />
      </div>
    </GapAround>
  );
};

export default WhyUs;
