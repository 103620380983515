
import LandingPage from './pages/LandingPage';
import WithNavbarAndFooter from './components/hoc/WithNavbarAndFooter';
import { Routes, Route } from 'react-router-dom';
import { Fragment } from 'react';
import classNames from 'classnames';
import About from './pages/About';
import Projects from './pages/Projects';
import ContactUsPage from './pages/ContactUsPage';
import MobileAppDev from './pages/MobileAppDev';
import WebDevServices from './pages/WebDevServices';
import Ux from './pages/Ux';
import SoftwareConsulting from './pages/SoftwareConsulting';
import { ThemeProvider } from '@emotion/react';
import { theme } from './constants';

export default function AppRoutes () {
    return (
          <div style={{ position: 'relative' }}>
      <ThemeProvider theme={theme}>
          <div className={classNames('relative')}>
            <WithNavbarAndFooter>
              <Routes>
                <Route
                  path="/"
                  element={
                      <LandingPage />
                  }
                />
                {/* TODO: Put back blogs */}
                {/* <Route
                  path="/blogs"
                  element={
                    <WithNavbarAndFooter>
                      <Blog />
                    </WithNavbarAndFooter>
                  }
                />
                <Route
                  path="/blogs/:slug"
                  element={
                    <WithNavbarAndFooter>
                      <BlogArticle />
                    </WithNavbarAndFooter>
                  }
                /> */}
                <Route
                  path="/about"
                  element={
                    // <WithNavbarAndFooter>
                      <About />
                    // </WithNavbarAndFooter>
                  }
                />
                <Route
                  path="/projects"
                  element={
                    // <WithNavbarAndFooter>
                      <Projects />
                    // </WithNavbarAndFooter>
                  }
                />
                <Route
                  path="/contact-us"
                  element={
                    // <WithNavbarAndFooter>
                      <ContactUsPage />
                    // </WithNavbarAndFooter>
                  }
                />
                <Route
                  path="/mobile-app-development"
                  element={
                    // <WithNavbarAndFooter>
                      <MobileAppDev />
                    // </WithNavbarAndFooter>
                  }
                />
                <Route
                  path="/web-app-development"
                  element={
                    // <WithNavbarAndFooter>
                      <WebDevServices />
                    // </WithNavbarAndFooter>
                  }
                />
                <Route
                  path="/ui-ux"
                  element={
                    // <WithNavbarAndFooter>
                      <Ux />
                    // </WithNavbarAndFooter>
                  }
                />
                <Route
                  path="/software-consultation"
                  element={
                    // <WithNavbarAndFooter>
                      <SoftwareConsulting />
                    // </WithNavbarAndFooter>
                  }
                />
                {/* <Route
                  path="instant-quote"
                  element={
                    <WithNavbarAndFooter>
                      <InstantQuote />
                    </WithNavbarAndFooter>
                  }
                /> */}
                {/* <Route
              path="our-team"
              element={
                <WithNavbarAndFooter>
                  <OurTeamPage />
                </WithNavbarAndFooter>
              }
            /> */}
              </Routes>
              </WithNavbarAndFooter>
          </div>
          {/* <FooterSection />
          <FlowerOne />
          <FlowerTwo />
          <FlowerThree /> */}
      </ThemeProvider>
    </div>)
}