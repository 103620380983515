import { CSSProperties } from 'react';
import { TwContainer } from '../../typings/tw.types';
import classNames from 'classnames';

interface GapAroundProps extends TwContainer {
  spaceTaken?: number;
  containerStyle?: CSSProperties;
  containerClass?: string;
  className?: string;
}

const GapAround = ({ children, spaceTaken = 0, containerStyle, containerClass, childrenClasses, className }: GapAroundProps) => {
  return (
    <div className={`flex justify-center items-center relative min-h-full ${containerClass}`} style={{ ...containerStyle }}>
      <div className={`${classNames(childrenClasses)} ${className} 3xl:max-w-[1360px] max-w-[1140px] w-full px-[20px]`}>
        {children}
      </div>
    </div>
  );
};

export default GapAround;
