import React, { createContext, useContext, useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';

const MediaQueryContext = createContext<any>(null);

export const MediaQueryProvider = ({ children }: React.PropsWithChildren) => {
  // Define media queries with `defaultMatches` to handle SSR
  const isLessThan640 = useMediaQuery('(max-width: 640px)', { defaultMatches: false });
  const isLessThan1150 = useMediaQuery('(max-width: 1150px)', { defaultMatches: false });
  const isLargeThan899 = useMediaQuery('(min-width: 899px)', { defaultMatches: false });
  const isLessThan1280 = useMediaQuery('(max-width: 1280px)', { defaultMatches: false });
  const isLessThan1024 = useMediaQuery('(max-width: 1024px)', { defaultMatches: false });
  const isLessThan899 = useMediaQuery('(max-width: 899px)', { defaultMatches: false });

  // Track `mounted` state for client-side only behavior
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  // Apply styles only on the client side after mounting
  const bigColumnClass = mounted && isLessThan1150 && isLargeThan899 ? {
    paddingLeft: '60px',
    paddingRight: '50px',
    marginBottom: '50px'
  } : undefined;

  const contextValue = {
    isLessThan640,
    isLessThan1150,
    isLargeThan899,
    isLessThan1280,
    bigColumnClass,
    isLessThan1024,
    isLessThan899,
  };

  return (
    <MediaQueryContext.Provider value={contextValue}>
      {children}
    </MediaQueryContext.Provider>
  );
};

// Custom hook to access the MediaQueryContext
export const useMediaQueries = () => {
  const context = useContext(MediaQueryContext);
  if (context === null) {
    throw new Error('useMediaQueries must be used within a MediaQueryProvider');
  }
  return context;
};
