interface CustomScriptElement extends HTMLScriptElement {
  [key: string]: any; // This allows any string index
}

export const loadScript = (scriptUrl: string, otherProps: any = {}) => {
  return new Promise<void>((resolve, reject) => {
    const scriptElement: CustomScriptElement = document.createElement('script');
    scriptElement.src = scriptUrl;
    for (let prop in otherProps) {
      scriptElement[prop] = otherProps[prop];
    }
    scriptElement.defer = true;
    scriptElement.onload = () => {
      resolve();
    };
    scriptElement.onerror = () => reject(new Error(`Failed to load script: ${scriptUrl}`));
    document.head.appendChild(scriptElement);
  });
};

export const executeScripts = () => {
  // Load the GTM script and wait for it to be ready
  loadScript('https://www.googletagmanager.com/gtag/js?id=G-2SNDEPYFGQ');

  // Initialize the dataLayer and gtag function only after the script is loaded
  window.dataLayer = window.dataLayer || [];
  function gtag(...args: any[]) {
    window.dataLayer.push(args);
  }
  window.gtag = gtag; // Make sure gtag is accessible globally

  // Call gtag after it's defined
  gtag('js', new Date());
  gtag('config', 'G-2SNDEPYFGQ');

  // Load the Termly script
  // loadScript('https://app.termly.io/embed.min.js', {
  //   type: 'text/javascript',
  //   'data-auto-block': 'on',
  //   'data-website-uuid': '4bc15cd1-5321-4b25-88a8-049b360120a1',
  // });
};
