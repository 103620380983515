import Info from '../../../components/common/Info';
import React from 'react';
import { useTranslation } from 'react-i18next';
import RoundInfo from '../../../components/common/RoundInfo';
import GapAround from '../../../components/hoc/GapAround';
import { useMediaQueries } from '../../../contexts/media-query';
import SoftwareList from './SoftwareList';
import WithNavbarHeightApplied from '../../../components/hoc/WithNavbarHeightApplied';
import BigColumn from '../../../components/layout/BigColumn';

function SoftwareServiceBanner(props: any) {
  const { pillLabel } = props;
  const { t } = useTranslation();
  const { isLessThan1280, isLessThan640, bigColumnClass } = useMediaQueries();
  const labels = [
    'Healthcare',
    'Finance',
    'Education',
    'Manufacturing',
    'Transportation & Logistics',
    'Telecommunications',
    'Automobile',
    'Real Estate',
    'Hospitality & Tourism',
    'Insurance',
    'Retail',
    '...and more!',
  ];

  return (
    <div className="my-16 breaktwo:mb-12 breaktwo:mt-5 xl:mt-24">
      <WithNavbarHeightApplied>
        <GapAround spaceTaken={2}>
          <BigColumn
            containerStyle={bigColumnClass}
            childStyle={{ justifyContent: bigColumnClass ? 'flex-end' : 'center' }}>
            {/* Left information section start */}
            <div className="isMin1280:pl-[27px] pr-4 breaktwo:flex breaktwo:flex-col justify-center items-center breaktwo:text-center breaktwo:items-center breaktwo:justify-center before:bg-top before:absolute before:w-full before:h-full before:-z-10  before:opacity-20">
              <RoundInfo containerClasses="breaktwo:mb-3 mb-6">{t(pillLabel)}</RoundInfo>
              <Info
                typographyStyles={{
                  fontSize: isLessThan1280 ? (isLessThan640 ? 32 : 36) : 42,
                }}>
                {'Industry-Specific Software'}
              </Info>
            </div>
            {/* Left information section end */}
            <div className="isMin1280:pr-[27px] flex justify-end breaktwo:justify-center breaktwo:mt-8 breaktwo:text-center breakone:text-center mb-8 breaktwo:mb-4 breaktwo:px-4">
              <SoftwareList items={labels} />
            </div>
          </BigColumn>
        </GapAround>
      </WithNavbarHeightApplied>
    </div>
  );
}

export default SoftwareServiceBanner;
