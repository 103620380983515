import { Grid } from '@mui/material';
import { CSSProperties } from 'react';

const BigColumn = ({ children, containerStyle,childStyle={}, className }: { children: any; containerStyle?: CSSProperties; childStyle?:CSSProperties, className?: string }) => {
  const numberOfItems = children?.length;

  return (
    <Grid container style={{ height: '100%', ...containerStyle }}>
      {numberOfItems > 0
        ? [...Array(numberOfItems)].map((_, index) => (
            <Grid key={index} container item xs={12} md={12 / numberOfItems} className={className} style = {{...childStyle}}>
              {children[index]}
            </Grid>
          ))
        : children}
    </Grid>
  );
};

export default BigColumn;
