import React from 'react';
import { hydrateRoot, createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { storyblokInit, apiPlugin } from '@storyblok/react';
import { MediaQueryProvider } from './contexts/media-query';
import { HelmetProvider } from 'react-helmet-async';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

storyblokInit({
  accessToken: process.env.REACT_APP_STORYBLOK_API_KEY,
  use: [apiPlugin],
  components: {},
  apiOptions: {
    region: 'us',
  },
});

const rootElement = document.getElementById('root');
if (!rootElement) {
  throw new Error('Root element not found');
}
const cache = createCache({ key: 'css' });
const jsx = (
  <CacheProvider value={cache}>
  <React.StrictMode>
    <HelmetProvider>
      <MediaQueryProvider>
        <App />
      </MediaQueryProvider>
    </HelmetProvider>
  </React.StrictMode>
  </CacheProvider>
);

if (rootElement.hasChildNodes()) {
  hydrateRoot(rootElement, jsx);
} else {
  createRoot(rootElement).render(jsx);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
