import { Typography, useMediaQuery } from '@mui/material';
import classNames from 'classnames';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSSR } from 'use-ssr';
import GapAround from '../../components/hoc/GapAround';
import { imageUrl, linkOffsets } from '../../utils';

const whiteLogo = imageUrl('atini-logo-white.svg');
const upwork = imageUrl('upwork.svg');
const linkedin = imageUrl('linkedin.svg');
const instagram = imageUrl('instagram.svg');

const linksMap: any = {
  Home: '/',
  About: '/about',
  Projects: '/projects',
  'Contact Us': '/contact-us',
  'Web App Development': '/web-app-development',
  'Mobile App Development': '/mobile-app-development',
  'UI/UX': '/ui-ux',
  'Software Consultation': '/software-consultation',
};

const FooterColumn = ({ data, noCursor }: { data: string[]; noCursor?: boolean }) => {
  const headingClasses = 'text-white mb-5 breaktwo:mb-2';
  const subHeadingClasses = classNames(headingClasses, {
    'cursor-pointer': !noCursor,
    'hover:text-yellow': !noCursor,
    'transition-colors duration-200': !noCursor,
  });
  const { isBrowser } = useSSR();
  const navigate = useNavigate();
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  const handleFooterlinkClick = useCallback(
    (param: string, index: number) => {
      if (isHomePage) return;
      navigate('/');
      const elementId =
        param === 'Reviews'
          ? 'clients'
          : param === "FAQ's"
          ? 'faqs'
          : // : param === 'Our Team'
            // ? 'our-team'
            param.toLowerCase();
      // if (elementId === 'our-team') {
      //   navigate('/our-team');
      // }
      setTimeout(() => {
        if (isBrowser) {
          document.getElementById(`${elementId}link`)?.click();
          const offset = linkOffsets[index];
          window.scrollTo({ top: offset, behavior: 'smooth' });
        }
      }, 500);
    },
    [isHomePage, navigate],
  );

  return (
    <div className="justify-between">
      {data.map((each: string, index: number) => (
        <div key={index} className={index === 0 ? headingClasses : subHeadingClasses}>
          {noCursor || index === 0 ? (
            <Typography
              variant={index === 0 ? 'subtitle1' : 'subtitle2'}
              style={{
                fontFamily: index === 0 ? 'var(--fontBase)' : 'var(--fontPrimary)',
                fontSize: index === 0 ? undefined : '100%',
                fontWeight: index === 0 ? undefined : 400,
              }}>
              {each}
            </Typography>
          ) : (
            <Link to={linksMap[each]} className={subHeadingClasses}>
              <Typography
                variant={index === 0 ? 'subtitle1' : 'subtitle2'}
                style={{
                  fontFamily: index === 0 ? 'var(--fontBase)' : 'var(--fontPrimary)',
                  fontSize: index === 0 ? undefined : '100%',
                  fontWeight: index === 0 ? undefined : 400,
                }}>
                {each}
              </Typography>
            </Link>
          )}
        </div>
      ))}
    </div>
  );
};

const FooterSection = () => {
  const { t } = useTranslation();
  const isLessThan640 = useMediaQuery('(max-width: 640px)');
  const isLessThan1150 = useMediaQuery('(max-width: 1150px)');
  const firstRowData = ['Links', 'Home', 'About', 'Projects', 'Contact Us'];
  const secondRowData = [t('services'), t('web_dev'), t('mobile_dev'), t('ui_ux'), t('software_consultation')];
  const thirdRowData = ['Social Links', 'Linked In', 'Instagram', 'Upwork'];

  const logoClass = isLessThan1150 ? 'flex flex-col w-32' : 'flex flex-col w-32';

  return (
    <div className="bg-footer-bg">
      <GapAround>
        <section className="mdmax:flex-wrap flex justify-between py-10 breakone:max-w-[750px] max-w-[880px] mx-auto">
          <div className="breakone:w-[150px] mdmax:text-center mdmax:mb-[30px] w-[210px] pt-[10px] mdmax:order-1">
            <img
              loading="lazy"
              width={'128px'}
              height={'112px'}
              className="max-w-full h-auto breaktwo:h-28 mdmax:ml-[-13px]"
              src={whiteLogo}
              alt="logo"
            />
          </div>
          <div className="w-[calc(33%-104px)] mdmax:!w-[50%] mdmax:!pl-[0] pl-[20px] mdmax:order-3">
            <FooterColumn data={firstRowData} />
          </div>
          <div className="breakone:w-[calc(33%)] mdmax:!w-[50%] w-[calc(33%-54px)] pl-[20px] mdmax:order-4">
            <FooterColumn data={secondRowData} />
          </div>
          <div className="md:max-w-[calc(33%-50px)] mdmax:!w-[50%] mdmax:mb-[35px] mdmax:ml-auto mdmax:pl-[20px] mdmax:order-2 pl-[20px] mdmax:self-center">
            <h6 className="text-[#fff] text-[22px] mb-[20px] whitespace-nowrap leading-[1.75]">Social Links</h6>

            <div className="flex items-center gap-[15px]">
              <a
                className="hover:opacity-[80%]"
                href="https://www.linkedin.com/company/atini-studio-inc"
                target="_blank">
                <img loading="lazy" width={'25px'} height={'25px'} src={linkedin} alt="linkedin" />
              </a>
              <a className="hover:opacity-[80%]" href="https://www.instagram.com/atinistudio/" target="_blank">
                <img loading="lazy" width={'25px'} height={'25px'} src={instagram} alt="instagram" />
              </a>
              <a className="hover:opacity-[80%]" href="https://www.upwork.com/agencies/atinistudio/" target="_blank">
                <img loading="lazy" width={'25px'} height={'25px'} src={upwork} alt="upwork" />
              </a>
            </div>
          </div>
        </section>
      </GapAround>
      <div className="text-white flex justify-between items-center sm:items-between bg-light-black w-screen py-1 px-5 md:px-10 text-xs flex-col md:flex-row lg:px-28 sm:text-sm">
        <div>&copy; Atini Studio {new Date().getFullYear()}. All rights reserved.</div>
        <div className="flex gap-5">
          <a
            href="#"
            onClick={() => {
              const w = window as any;
              w.displayPreferenceModal();
              return false;
            }}
            id="termly-consent-preferences">
            Cookie Preferences
          </a>
          <a href="https://app.termly.io/document/privacy-policy/bcd1e0db-e936-45ba-b6b0-874dfe47f9a0" target="_blank">
            Privacy Policy
          </a>
          <a
            href="https://app.termly.io/document/terms-of-service/0ed1f37e-4c9a-49e0-aa57-6499949a524a"
            target="_blank">
            Terms of Service
          </a>
        </div>
      </div>
    </div>
  );
};

export default FooterSection;
