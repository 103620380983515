import React from 'react'
import Header from '../components/common/Header'
import Banner from '../components/common/Banner'
import ServiceList from '../views/dev/ServiceList';
import ProjectsSection from '../views/landing/ProjectsSection';
import { ux_ServiceItems } from '../constants/servicesItems';
import FaqSection from '../views/landing/FaqSection';
import {uiuxFaqs} from '../constants/uiuxFaqs';
interface Props { }

const Ux = (props: Props) => {
    const { } = props

    return (
        <>
            <Header
                pillLabel='Ui_Ux_Pill'
                title='Ui_Ux'
                description='ui_ux_description'
                renderButton
                buttonText='get_instant_quote'
                image='ux_header.svg'
            />
            <Banner
                pillLabel= 'elegant_design_pill'
                title='ui_ux_banner_motto'
                description='ui_ux_banner_description'
            />
            <ServiceList
                items = {ux_ServiceItems}
            />
            <ProjectsSection />
            <FaqSection questionAnswerArray={uiuxFaqs}/>
        </>
    )
}
export default Ux