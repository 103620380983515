import React from 'react';
import ResultBox from '../../../components/common/ResultBox';
import { useTranslation } from 'react-i18next';
import GapAround from '../../../components/hoc/GapAround';
import { useMediaQueries } from '../../../contexts/media-query';

const MobileDevServices = () => {
  const { t } = useTranslation();
  const { isLessThan640, isLessThan1280, isLessThan1150, isLessThan899 } = useMediaQueries();

  const services = [
    {
      heading: 'ios',
      subheading: 'ios_app_dev',
      headingStyle: { color: 'white' },
      imgSrc: 'ios.svg',
      bgColor: 'bg-light-green',
      fontColor: 'white',
    },
    { heading: 'android', subheading: 'android_app_dev', imgSrc: 'android.svg', bgColor: 'bg-[#F3F1F2]' },
    { heading: 'developer', subheading: 'hire_dev', imgSrc: 'developer.svg', bgColor: 'bg-[#F8F8F8]' },
    { heading: 'custom', subheading: 'custom_app_dev', imgSrc: 'custom.svg', bgColor: 'bg-[#F8F8F8]' },
  ];

  return (
    <GapAround
      containerStyle={{ marginTop: isLessThan1150 ? '1.5rem' : '3rem', marginBottom: isLessThan899 ? '3rem' : '5rem' }}>
      <div
        className="flex breaktwo:flex-col justify-center mx-auto breaktwo:space-y-4 breaktwo:space-x-0 space-x-4"
        style={{ width: isLessThan640 ? '90vw' : isLessThan899 ? '50vw' : '' }}>
        {services.map((serviceObj) => {
          const { heading, subheading, headingStyle, imgSrc, bgColor, fontColor }: any = serviceObj;
          const boxHeadingStyle = headingStyle ?? {};
          const boxFontColor: any = fontColor ?? '';
          return (
            <ResultBox
              key={heading + subheading}
              containerClasses={`${isLessThan1150 ? 'min-w-[200px]' : 'min-w-[250px]'} ${bgColor} ${
                isLessThan1150 ? 'p-4' : 'p-7'
              }`}
              heading={t(heading)}
              subheading={t(subheading)}
              describeImage={imgSrc}
              headingStyle={{
                ...boxHeadingStyle,
                fontSize: isLessThan1280 ? (isLessThan1150 ? (isLessThan640 ? 24 : 30) : 24) : 30,
              }}
              subHeadingStyle={
                isLessThan640
                  ? { fontSize: 14, color: boxFontColor }
                  : { color: boxFontColor, fontSize: 14, marginTop: '1rem' }
              }
              iconStyle={{
                width: isLessThan1150 ? 25 : 40,
                height: isLessThan1150 ? 25 : 40,
              }}
              noWidth
            />
          );
        })}
      </div>
    </GapAround>
  );
};

export default MobileDevServices;
