import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Info from '../../components/common/Info';
import GapAround from '../../components/hoc/GapAround'
import { imageUrl } from '../../utils';

const TrustedBySection = () => {
  const everydayInclusionLogo = imageUrl('everyday_inclusion_logo.webp');
  const everydayInclusionLogoM = imageUrl('everyday_inclusion_logo-m.webp');
  const leadAutopilotLogo = imageUrl('lead_autopilot.webp');
  const leadAutopilotLogoM = imageUrl('lead_autopilot-m.webp');
  const apexcareLogo = imageUrl('apexcare_logo.webp');
  const apexcareLogoM = imageUrl('apexcare_logo-m.webp');
  const aresLogo = imageUrl('ares_logo.webp');
  const aresLogoM = imageUrl('ares_logo-m.webp');
  const upkeepLogo = imageUrl('upkeep_logo.webp');
  const upkeepLogoM = imageUrl('upkeep_logo-m.webp');
  const { t } = useTranslation();

  const trustedCompanies = [
    {
      mobileViewSrc: everydayInclusionLogoM,
      src: everydayInclusionLogo,
    },
    {
      mobileViewSrc: leadAutopilotLogoM,
      src: leadAutopilotLogo,
    },
    {
      mobileViewSrc: apexcareLogoM,
      src: apexcareLogo,
    },
    {
      mobileViewSrc: aresLogoM,
      src: aresLogo,
    },
    {
      mobileViewSrc: upkeepLogoM,
      src: upkeepLogo,
    },
  ];
  return (
    <div className={classNames('pb-14 border-y-2 border-t-0 breaktwo:pb-8')}>
      <GapAround>
        <Info className="lg:!text-[36px] md:!text-[28px] !text-[28px]"
          typographyStyles={{ fontWeight: 500}}
          containerClasses={'text-center mt-5 mb-6 md:mb-16'}>
          {t('trusted_by')}
        </Info>
        <div className="flex justify-center items-center flex-wrap gap-6 md:gap-12">
          {trustedCompanies.map((each, index) => {
            return (
              <div
                key={`trusted${index}`}
                className="flex justify-center items-center w-24 mdmax:max-w-[25%] md:w-32 lg:w-40 mt-0 md:-mt-10">
                <picture>
                  <source media="(min-width:768px)" srcSet={each.src} />
                  <img
                    loading="lazy"
                    width={'160px'}
                    height={'76px'}
                    key={index}
                    src={each.mobileViewSrc}
                    className="max-w-full"
                    alt=""
                  />
                </picture>
              </div>
            );
          })}
        </div>
      </GapAround>
    </div>
  );
};

export default TrustedBySection;
