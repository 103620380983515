import { Typography } from '@mui/material';
import { TwContainer } from '../../typings/tw.types';
import classNames from 'classnames';

interface PillProp extends TwContainer {}

const Pill = ({ children, containerClasses, typographyStyles, ...props }: PillProp) => {
  return (
    <div className={classNames('rounded-full px-2 py-1 flex justify-center items-center', containerClasses)} {...props}>
      <Typography
        variant="subinformation"
        style={{ fontFamily: 'var(--fontPrimary)', fontSize: 12, ...typographyStyles }}>
        {children}
      </Typography>
    </div>
  );
};

export default Pill;
