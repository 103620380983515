import Info from '../../components/common/Info';
import GapAround from '../../components/hoc/GapAround';
import QuestionAnswer from '../../components/common/QuestionAnswer';
import SubInfo from '../../components/common/SubInfo';
import { RoundedArrowButton } from '../../components/buttons';
import { imageUrl } from '../../utils';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface IQuestionAnswer {
  question: string;
  answer: string;
}

const FaqSection = ({ questionAnswerArray }: { questionAnswerArray: IQuestionAnswer[] }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isLessThan640 = useMediaQuery('(max-width: 640px)');
  const isLessThan1280 = useMediaQuery('(max-width: 1280px)');

  return (
    <GapAround className='breaktwomin:px-[0]' childrenClasses="max-w-[920px] bg-very-light-gray rounded-xl flex items-center flex-col relative mdmax:my-16 my-20">
      <Info
        id="faqs"
        containerClasses="text-center pt-10 mb-5 md:mb-10"
        typographyStyles={{ color: '#263238', fontSize: isLessThan1280 ? (isLessThan640 ? 32 : 36) : 42 }}>
        {t('faq')}
      </Info>

      {questionAnswerArray.map((each, index) => {
        return <QuestionAnswer question={each.question} answer={each.answer} key={`qa${index}`} />;
      })}

      <img
        className="absolute -top-8 right-10 z-1 h-16 sm:h-auto"
        src={imageUrl('question_mark_double_border.svg')}
        alt="question-mark"
      />
      <img
        className="absolute left-10 sm:left-20 top-20 sm:top-24 scale-50"
        src={imageUrl('question_mark_double_border.svg')}
        alt="question-mark"
      />
      <img
        className="absolute bottom-[20%] right-10 scale-50 -rotate-45 breaktwo:hidden"
        src={imageUrl('question_mark_double_border.svg')}
        alt="question-mark"
      />

      <div className="flex justify-between mdmax:!w-[100%] w-9/12 border-t-2 py-6 sm:py-10 mt-6 sm:mt-10 breaktwo:flex-col breaktwo:items-center breaktwo:text-center">
        <div>
          <SubInfo typographyStyles={{ fontWeight: 500, color: '#263238', fontSize: 25 }}>
            {t('have_more_questions')}
          </SubInfo>
          <SubInfo typographyStyles={{ color: '#728F8B' }} containerClasses="mt-1">
            {t('get_in_touch')}
          </SubInfo>
        </div>
        <div>
          <RoundedArrowButton
            text={t('talk_to_expert')}
            typographyStyles={{ fontWeight: 300 }}
            arrowContainerClasses="ml-10"
            containerClasses="breaktwo:w-70 breaktwo:mt-6 text-transform:none"
            onClick={() => navigate('/contact-us')}
          />
        </div>
      </div>
    </GapAround>
  );
};

export default FaqSection;
