import React from 'react';
import Header from '../components/common/Header';
import ContactUs from '../views/landing/ContactUs';

interface Props {}

const ContactUsPage = (props: Props) => {
  const {} = props;

  return (
    <>
      <Header
        pillLabel="contact_us"
        title="content_us_motto"
        description="contact_us_description"
        image="contact_us.svg"
        renderButton={false}
      />
      <ContactUs />

      <iframe
        className="w-full border-none"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3227.74278254182!2d-115.1945229241979!3d36.00215097248542!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c8c9338293a8d7%3A0x4bb00a4f780bd57b!2s3946%20Painted%20Lady%20Ave%2C%20Las%20Vegas%2C%20NV%2089141%2C%20USA!5e0!3m2!1sen!2sin!4v1733898241774!5m2!1sen!2sin"
        width="600"
        height="450"
        loading="lazy"
        data-categories="essential"
        data-allow-scripts="true"
        referrerPolicy="no-referrer-when-downgrade"></iframe>
    </>
  );
};

export default ContactUsPage;
