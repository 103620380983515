import React from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { CircularProgress, Typography } from '@mui/material';
import classNames from 'classnames';
import { TwContainer } from '../../typings/tw.types';
import { motion } from 'framer-motion';
import useSSR from 'use-ssr';

interface RoundedArrowButtonProps extends TwContainer {
  text: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  arrowContainerClasses?: string;
  arrowColor?: string;
  centerText?: boolean;
  jumpToDivId?: string;
  loading?: boolean;
  movingArrow?: boolean;
}

export const RoundedArrowButton = ({
  text,
  onClick,
  arrowContainerClasses,
  containerClasses,
  arrowColor = 'white',
  typographyStyles,
  centerText = false,
  jumpToDivId,
  loading,
  movingArrow = false,
}: RoundedArrowButtonProps) => {
  const defaultClassesContainer = classNames(
    `flex rounded-full border-[1px] border-[#263238] items-center justify-between whitespace-nowrap ${
      loading ? '' : 'cursor-pointer'
    }`,
    containerClasses,
  );

  const defaultClassesArrowContainer = classNames(
    'bg-black flex items-center justify-center rounded-full p-2 text-center m-3 breaktwo:p-1',
    arrowContainerClasses,
  );

  const arrowMotion = {
    rest: { ease: 'easeOut', duration: 0.2, type: 'tween', x: -3 },
    hover: {
      x: 0,
      transition: {
        duration: 0.2,
        type: 'tween',
        ease: 'easeIn',
      },
    },
  };

  const { isBrowser} = useSSR();

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (loading) {
      return;
    }
    event.stopPropagation();
    if (jumpToDivId) {
      if(isBrowser) {
        const targetDiv = document.getElementById(jumpToDivId);
        if (targetDiv) {
          targetDiv.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
      }
    }
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <motion.div
      onClick={handleClick}
      className={defaultClassesContainer}
      initial="rest"
      whileHover="hover"
      animate="rest">
      {centerText && (
        <div className={classNames(defaultClassesArrowContainer, 'opacity-0')}>
          <ArrowForwardIcon style={{ fill: arrowColor, fontSize: 16, textAlign: 'center' }} />
        </div>
      )}
      {loading ? (
        <CircularProgress size={30} style={{ color: 'white' }} />
      ) : (
        <Typography
          variant="button"
          sx={{
            ml: centerText ? undefined : 3,
            fontFamily: 'Questrial, arial, sans-serif',
            ...typographyStyles,
          }}>
          {text}
        </Typography>
      )}
      {movingArrow ? (
        <motion.div
          className={classNames(defaultClassesArrowContainer)}
          animate={{ x: -6 }}
          transition={{ type: 'tween', duration: 0.9, repeat: Infinity, ease: 'easeOut' }}>
          <ArrowForwardIcon style={{ fill: arrowColor, fontSize: 20, textAlign: 'center' }} />
        </motion.div>
      ) : (
        <motion.div className={classNames(defaultClassesArrowContainer)} variants={arrowMotion}>
          <ArrowForwardIcon style={{ fill: arrowColor, fontSize: 20, textAlign: 'center' }} />
        </motion.div>
      )}
    </motion.div>
  );
};
