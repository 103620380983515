import {  BrowserRouter } from 'react-router-dom';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import EnglishTranslation from './i18n/en.json';
import ChineseTranslation from './i18n/zh.json';
import AppRoutes from './appRoutes';
import { Helmet } from 'react-helmet-async';
import React, { useEffect } from 'react';
import { executeScripts } from './utils';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    supportedLngs: ['en', 'zh'],
    resources: {
      en: {
        translation: EnglishTranslation,
      },
      zh: {
        translation: ChineseTranslation,
      },
    },
    fallbackLng: 'en',
    detection: {
      order: ['path', 'querystring', 'cookie', 'htmlTag', 'localStorage', 'path', 'subdomain'],
      caches: ['cookie'],
    },
  });

function App({isSSR, location}: Readonly<{isSSR?: boolean, location?: string}>) {

  useEffect(() => {
    setTimeout(() => {
      executeScripts()
    }, 10000)
  }, []);

  (globalThis as any).SSR_PATHNAME = location;

  return (
    <>
    <Helmet>
        <title>Atini Studio | Custom Software Development</title>
        <meta
          name="description"
          content="A team of software developers and designers to build web/mobile app according to your vision. Whether from scratch or simply maintaining an existing application."
        />
      </Helmet>
    {isSSR ? (
    <AppRoutes />
  ) : (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  )}
  </>
  )
}

export default App;
