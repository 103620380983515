import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ComponentDivProps } from '../../typings/html.types';
import FooterSection from '../../views/landing/FooterSection';
import Navbar from '../layout/Navbar';
import useSSR from 'use-ssr';

const WithNavbarAndFooter = ({ children }: ComponentDivProps) => {
  const location = useLocation();
  const { isBrowser } = useSSR();

  useEffect(() => {
    if (isBrowser) {
      window?.scrollTo(0, 0);
    }
  }, [location]);
  return (
    <div>
      <Navbar />
      <div style={{ marginTop: '8rem' }}>{children}</div>
      <FooterSection />
    </div>
  );
};

export default WithNavbarAndFooter;
