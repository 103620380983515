import classNames from 'classnames';
import { TwContainer } from '../../typings/tw.types';
import { imageUrl } from '../../utils';

interface ExpertiseCardProps extends TwContainer {
  name: string;
  icon: string;
}

const ExpertiseCard = ({ name, icon, containerClasses }: ExpertiseCardProps) => {
  return (
    <div className={classNames('flex flex-col items-center p-10 breaktwo:border-t-[0.5px]', containerClasses)}>
      <div className="h-20 w-20 flex items-center justify-center">
        <img className="max-h-14" src={imageUrl(icon)} alt=""></img>
      </div>
      <div className="text-center whitespace-nowrap text-[#728F8B]" style={{ fontFamily: 'var(--fontPrimary)' }}>
        {name}
      </div>
    </div>
  );
};

export default ExpertiseCard;
